import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ClaimScoreLoader, setupFeatureFlag, useLDClient } from 'ui';
import { FirebaseAuth, User } from 'services/FirebaseService/auth';

interface AuthContextProps {
  user: User | null;
  isAuthenticated: boolean;
  login: (user: User) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  isAuthenticated: false,
  login: () => undefined,
  logout: () => undefined,
});

export function AuthProvider({ children }: PropsWithChildren) {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const launchdarklyClient = useLDClient();
  const [loadingFlags, setLoadingFlags] = useState(true);
  const [loadingUser, setLoadingUser] = useState(true);

  const login = (newUser: User) => {
    setUser(newUser);
    setIsAuthenticated(true);
  };

  const logout = () => {
    FirebaseAuth.signOut();
    setUser(null);
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const unsubscribe = FirebaseAuth.subscribeToUser((authUser) => {
      if (authUser) {
        login(authUser as User);
      } else {
        logout();
      }
      setLoadingUser(false);
    });
    return () => unsubscribe();
  }, [login]);

  useEffect(() => {
    if (!launchdarklyClient) return;
    if (user) {
      const setup = async () => {
        await setupFeatureFlag({ launchdarklyClient, user });
      };
      setup();
    }
    setLoadingFlags(false);
  }, [launchdarklyClient, user]);

  const authProviderValues = useMemo(
    () => ({
      user,
      isAuthenticated,
      isLoading: loadingFlags || loadingUser,
      login,
      logout,
    }),
    [isAuthenticated, user],
  );

  if (loadingUser || loadingFlags) {
    return <ClaimScoreLoader />;
  }

  return (
    <AuthContext.Provider value={authProviderValues}>
      {children}
    </AuthContext.Provider>
  );
}
