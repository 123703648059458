import { styled } from '@mui/material';
import React from 'react';
import { Card } from '../Card';
import { Collapse } from '../Collapse';
import { SummaryCardField } from './SummaryCardField';
import { SummaryCardHeader } from './SummaryCardHeader';
import { SummaryCardTitle } from './SummaryCardTitle';

export const StyledSummaryCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing(2)};
  align-items: flex-start;
  border-radius: ${({ theme }) => theme.borderRadii.outer};
  width: 100%;
  height: 100%;
  box-shadow: ${({ theme }) => theme.boxShadow};
`;

interface SummaryCardProps {
  header: React.ReactNode;
  content: React.ReactNode;
  isCollapsible?: boolean;
  expandCard?: boolean;
}

export function SummaryCard({
  header,
  children,
  content,
  isCollapsible = false,
  expandCard,
}: React.PropsWithChildren<SummaryCardProps>) {
  return (
    <StyledSummaryCard>
      {header}
      {isCollapsible ? <Collapse in={expandCard}>{content}</Collapse> : content}
      {children}
    </StyledSummaryCard>
  );
}

SummaryCard.Header = SummaryCardHeader;
SummaryCard.Title = SummaryCardTitle;
SummaryCard.Field = SummaryCardField;
