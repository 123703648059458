import { differenceInDays, parseISO } from 'date-fns';
import { saveAs } from 'file-saver';
import millify from 'millify';
import {
  ColorOrange600,
  ColorRed600,
  ColorViolet600,
} from 'ui/src/design-system/colors';
import { UserOrgCaseFilters } from '@context/UserOrgCaseContext';
import { FiltersForm } from '@shared/types/forms';
import { getNewDate, toISOString } from '@utils/dates';
import { removeDecimals } from '@utils/numbers';
import { isEmptyString } from '@utils/strings';
import { GetCaseAggregateResultsResponse } from 'services/ClaimscoreApiService/Cases/types';
import {
  DashboardFilters,
  TimeInterval,
} from 'services/ClaimscoreApiService/shared/types';

export const spikeColorByIndex = [ColorOrange600, ColorViolet600, ColorRed600];

const rangesForLessThanNDays: Record<number, TimeInterval> = {
  // Less than 1 Day = Hourly Buckets
  0: TimeInterval.Hour,
  // Less than 5 Days = 4-hour buckets
  4: TimeInterval.FourHourBlock,
  // Less than a Month = daily Buckets
  30: TimeInterval.Day,
  // Less than a 4 Months = weekly buckets
  126: TimeInterval.Week,
  // Less than 2.5 years = Monthly Buckets
  942: TimeInterval.Month,
  // Less than 7 years = quarterly Buckets
  2555: TimeInterval.Quarter,
};
// If none of the ranges before work, use this.
const maximumBucketSize = TimeInterval.Quarter;

export const getTimeInterval = (startDate = '', endDate = ''): TimeInterval => {
  const range = differenceInDays(
    parseISO(startDate || ''),
    parseISO(endDate || ''),
  );
  let aggregationUnit: TimeInterval | undefined;
  Object.keys(rangesForLessThanNDays).forEach((amountOfDays) => {
    if (!aggregationUnit && Math.abs(range) <= Number(amountOfDays)) {
      aggregationUnit =
        rangesForLessThanNDays[
          // Todo: cleanup - use for loop
          amountOfDays as unknown as keyof typeof rangesForLessThanNDays
        ];
    }
  });
  if (!aggregationUnit) aggregationUnit = maximumBucketSize;

  return aggregationUnit;
};

const EndOfDayTime = { hour: 23, minute: 59, second: 59, millisecond: 0 };

export const mapDatesToTimezoneDates = (startDate = '', endDate = '') => {
  if (isEmptyString(startDate) || isEmptyString(endDate))
    return { startDateUTC: '', endDateUTC: '' };

  const startDateUTC = toISOString(new Date(startDate));
  const endDateUTC = getNewDate(endDate);
  endDateUTC.setUTCHours(
    EndOfDayTime.hour,
    EndOfDayTime.minute,
    EndOfDayTime.second,
    EndOfDayTime.millisecond,
  );
  return { startDateUTC, endDateUTC: toISOString(endDateUTC) };
};
export const mapFormToPayload = (filters: FiltersForm): DashboardFilters => {
  const {
    deductionCode,
    determination,
    payoutRange,
    scoreRange,
    unitRange,
    startDate,
    endDate,
  } = filters;

  const hasDetermination = determination && determination != null;
  const hasUnitRange = unitRange && unitRange.length > 0;
  const hasPayoutRange = payoutRange && payoutRange.length > 0;
  const hasScoreRange = scoreRange && scoreRange.length > 0;
  const hasDeductionCode = deductionCode && deductionCode != null;
  const { startDateUTC, endDateUTC } = mapDatesToTimezoneDates(
    startDate,
    endDate,
  );

  return {
    dateRange: {
      startDate: startDateUTC,
      endDate: endDateUTC,
    },
    determinations: hasDetermination ? [determination] : undefined,
    unitRange: hasUnitRange
      ? {
          min: unitRange?.[0],
          max: unitRange?.[1],
        }
      : undefined,
    payoutRange: hasPayoutRange
      ? {
          min: (payoutRange?.[0] || 0) * 100,
          max: (payoutRange?.[1] || 0) * 100,
        }
      : undefined,
    scoreRange: hasScoreRange
      ? {
          min: scoreRange?.[0],
          max: scoreRange?.[1],
        }
      : undefined,
    deductionCodes: hasDeductionCode ? [deductionCode] : undefined,
    timeInterval: getTimeInterval(startDate, endDate),
    claimTypes: [],
  };
};

interface Spike {
  spikeID: string;
  title: string;
  totalClaims: number;
  VSaverage: number;
  borderColor: string;
  claimData: { key: string; value: string }[];
  blocks: { title: string; info: { value: string; link: string }[] }[];
}

export const decodeSearchParams = (searchParams: URLSearchParams) =>
  [...searchParams.entries()].reduce((acc, [key, val]) => {
    try {
      return {
        ...acc,
        [key]: JSON.parse(val),
      };
    } catch {
      return {
        ...acc,
        [key]: val,
      };
    }
  }, {});

export function mapFilterToSearchQuery(obj: FiltersForm | UserOrgCaseFilters) {
  const filteredObj = Object.fromEntries(
    Object.entries(obj)
      .filter(
        ([, value]) =>
          value !== null &&
          value !== undefined &&
          value !== '' &&
          value !== 'undefined',
      )
      .map(([key, value]) => [
        key,
        typeof value === 'string' ? value : JSON.stringify(value),
      ]),
  );

  return new URLSearchParams(filteredObj);
}

export const mapAggregateToSpikes = ({
  buckets,
}: GetCaseAggregateResultsResponse): Spike[] => {
  const spikeBuckets = buckets.filter((bucket) => bucket.isSpike);
  const totalBuckets = buckets.filter(
    (bucket) =>
      bucket.deductionCodesCount && bucket.deductionCodesCount.length > 0,
  );

  const averageClaims =
    buckets.reduce(
      (acc, curr) =>
        acc + curr.totalApproved + curr.totalPending + curr.totalRejected,
      0,
    ) / (totalBuckets.length || 1);

  return spikeBuckets.map((bucket, index) => {
    const {
      totalApproved,
      totalPending,
      totalRejected,
      sourceCount,
      deductionCodesCount,
      totalClaims,
    } = bucket;
    const approvedPercentage = removeDecimals(
      (totalApproved / totalClaims) * 100,
    );
    const pendingPercentage = removeDecimals(
      (totalPending / totalClaims) * 100,
    );
    const rejectedPercentage = removeDecimals(
      (totalRejected / totalClaims) * 100,
    );
    return {
      title: `Spike ${index + 1}`,
      spikeID: bucket.bucketID,
      totalClaims,
      VSaverage: removeDecimals((totalClaims / averageClaims) * 100),
      borderColor: spikeColorByIndex[index],
      claimData: [
        {
          key: 'Approved',
          value: approvedPercentage === 0 ? 'N/A' : `${approvedPercentage}%`,
        },
        {
          key: 'Pending',
          value: pendingPercentage === 0 ? 'N/A' : `${pendingPercentage}%`,
        },
        {
          key: 'Rejected',
          value: rejectedPercentage === 0 ? 'N/A' : `${rejectedPercentage}%`,
        },
      ],
      blocks: [
        {
          title: 'Top Sources',
          info: (
            sourceCount?.sort((a, b) => b.count - a.count).slice(0, 3) || []
          ).map(({ source, count }) => ({
            link: source,
            value: millify(count),
          })),
        },
        {
          title: 'Top Rejected',
          info: (
            deductionCodesCount
              ?.sort((a, b) => b.count - a.count)
              .slice(0, 3) || []
          ).map(({ deductionCode, count }) => ({
            link: deductionCode,
            value: millify(count),
          })),
        },
      ],
    };
  });
};

export const generateReportCaseFile = (binaryFile: string) => {
  const blob = new Blob([binaryFile], {
    type: 'application/pdf',
  });
  saveAs(blob, `report.${'pdf'}`);
};

export const getInitialDate = (date: string, option: string) => date || option;
