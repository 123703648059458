import { Stack as StackMui, StackProps as StackPropsMui } from '@mui/material';
import React from 'react';

export type StackProps = Pick<
  StackPropsMui,
  | 'direction'
  | 'spacing'
  | 'children'
  | 'sx'
  | 'gap'
  | 'divider'
  | 'mb'
  | 'mt'
  | 'flex'
  | 'alignItems'
  | 'width'
  | 'justifyContent'
  | 'padding'
  | 'flexGrow'
  | 'flexWrap'
>;

export function Stack({ ...rest }: StackProps) {
  return <StackMui {...rest} />;
}
