import { styled } from '@mui/material';
import React from 'react';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

const StyledTypography = styled(Typography)`
  flex: 1;
  text-align: start;
`;

export const StyledStackItems = styled(Stack)<{ single?: boolean }>`
  padding: ${({ theme }) => theme.spacing(1)};
  border-bottom: ${({ single }) => (single ? '' : '1px solid')};
  border: ${({ single }) => (single ? '1px solid' : '')};
  border-color: ${({ theme }) => theme.palette.neutral[200]};
  border-radius: ${({ single, theme }) =>
    single ? theme.borderRadii.inner : ''};
`;

interface SummaryCardFieldProps {
  readonly label: string;
  readonly isCardTitle?: boolean;
  readonly color?: string;
  readonly single?: boolean;
}

export function SummaryCardField({
  label,
  isCardTitle = false,
  color,
  single = false,
  children,
}: React.PropsWithChildren<SummaryCardFieldProps>) {
  return (
    <StyledStackItems
      direction="row"
      justifyContent="space-between"
      single={single}
      width="100%"
    >
      <StyledTypography
        color={color}
        variant={isCardTitle ? 'captionStrong' : 'body'}
      >
        {label}
      </StyledTypography>
      {children}
    </StyledStackItems>
  );
}
