import { useMemo } from 'react';
import { useUserOrgCase } from '@context/UserOrgCaseContext';
import { useCaseDashboardContext } from '@pages/CaseDashboard/CaseDashboardContext';
import { getTodayDate } from '@utils/dates';

interface UseFiltersInitialDates {
  defaultStartDate: string;
  defaultEndDate: string;
  startDate: string;
  endDate: string;
}

export const useFiltersInitialDates = (): UseFiltersInitialDates => {
  const { filters } = useCaseDashboardContext();

  const { selectedCase } = useUserOrgCase();
  const today = useMemo(() => getTodayDate(), []);

  const submissionEndDate = useMemo(
    () =>
      selectedCase?.claimSubmissionEndDate > today
        ? today
        : selectedCase?.claimSubmissionEndDate,
    [selectedCase?.claimSubmissionEndDate, today],
  );

  const startDate = useMemo(
    () => filters?.startDate || selectedCase?.claimSubmissionStartDate,
    [selectedCase?.claimSubmissionStartDate, filters?.startDate],
  );

  const endDate = useMemo(
    () => filters?.endDate || submissionEndDate || today,
    [filters?.endDate, submissionEndDate],
  );

  return {
    defaultStartDate: selectedCase?.claimSubmissionStartDate,
    defaultEndDate: submissionEndDate,
    startDate,
    endDate,
  };
};
