/// <reference types="vite/client" />
import { initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import { Firestore, getFirestore } from 'firebase/firestore';
import { FirebaseStorage, getStorage } from 'firebase/storage';

class FirebaseManager {
  // eslint-disable-next-line no-use-before-define
  private static instance: FirebaseManager | null = null;
  private db: Firestore | null = null;
  private auth: Auth | null = null;
  private storage: FirebaseStorage | null = null;

  private constructor() {
    const firebaseConfig = {
      apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
      appId: import.meta.env.VITE_FIREBASE_APP_ID,
      authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
      messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
      projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
      storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    this.db = getFirestore(app);
    this.storage = getStorage(app);
    this.auth = getAuth(app);
    this.auth.settings.appVerificationDisabledForTesting =
      import.meta.env.VITE_APP_E2E_ENV;
  }

  public static getInstance(): FirebaseManager {
    if (!FirebaseManager.instance) {
      FirebaseManager.instance = new FirebaseManager();
    }
    return FirebaseManager.instance;
  }

  public getFirestore(): Firestore {
    if (!this.db) {
      throw new Error(
        'Firestore not initialized. Make sure to call getInstance() first.',
      );
    }
    return this.db;
  }

  public getAuth(): Auth {
    if (!this.auth) {
      throw new Error(
        'Firestore not initialized. Make sure to call getInstance() first.',
      );
    }
    return this.auth;
  }

  public getStorage(): FirebaseStorage {
    if (!this.storage) {
      throw new Error(
        'Firestore not initialized. Make sure to call getInstance() first.',
      );
    }
    return this.storage;
  }
}

// Export a singleton instance
const firebaseManager = FirebaseManager.getInstance();
export default firebaseManager;
