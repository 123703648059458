import toQueryParams from '../../utils/toQueryParams';
import claimScoreApiServiceClient from '../client';
import {
  GetCaseAccountsResponse,
  GetCaseAggregateResultsPayload,
  GetCaseAggregateResultsResponse,
  GetCaseResponse,
  GetClaimDeterminationResultsPayload,
  GetClaimDeterminationResultsResponse,
} from './types';

export const getCaseById = async (caseId: string): Promise<GetCaseResponse> => {
  const { data } = await claimScoreApiServiceClient.get(`/cases/${caseId}`);
  return data;
};

export const getCaseAccounts = async ({
  accountRole,
  accountStatus,
  caseId,
  pageNumber,
  search,
}: {
  accountRole?: string;
  accountStatus?: string;
  caseId: string;
  pageNumber: number;
  search?: string;
}): Promise<GetCaseAccountsResponse> => {
  const { data } = await claimScoreApiServiceClient.get(
    `/cases/${caseId}/accounts?${toQueryParams({
      accountRole,
      accountStatus,
      pageNumber,
      pageSize: 12,
      search,
    })}`,
  );

  return data;
};

export const getClaimDeterminationResults = async ({
  caseID,
  pageNumber,
  filters,
}: GetClaimDeterminationResultsPayload): Promise<GetClaimDeterminationResultsResponse> => {
  const {
      startDate,
      endDate,
      eligibleUnitRangeMin,
      eligibleUnitRangeMax,
      payoutAmountRangeMin,
      payoutAmountRangeMax,
      scoreRangeMin,
      scoreRangeMax,
      acceptedDeductionCodes,
      determinationFilters,
    } = filters,
    { data } = await claimScoreApiServiceClient.get(
      `/cases/${caseID}/results?${toQueryParams({
        acceptedDeductionCodes,
        determinationFilters,
        eligibleUnitRangeMax,
        eligibleUnitRangeMin,
        endDate,
        pageNumber,
        pageSize: 10,
        payoutAmountRangeMax,
        payoutAmountRangeMin,
        scoreRangeMax,
        scoreRangeMin,
        startDate,
      })}`,
    );

  return data;
};

export const getCaseAggregateResults = async ({
  caseID,
  filters,
}: GetCaseAggregateResultsPayload): Promise<GetCaseAggregateResultsResponse> => {
  const { data } = await claimScoreApiServiceClient.post(
    `/cases/${caseID}/aggregate`,
    { filters },
  );

  return data;
};
