import React, { useMemo } from 'react';
import { ColorNeutral600 } from '../../design-system/colors';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { IconButton } from '../IconButton';
import { InfoOutlinedIcon } from '../Icons/InfoOutlinedIcon';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

interface SummaryCardHeaderProps {
  readonly icon: React.ReactNode;
  readonly title: string;
  readonly onClickInfo: () => void;
  readonly actionButton?: React.ReactNode;
  readonly expandCard?: boolean;
}

const stackDesktopStyles = { marginBottom: 2 };

export function SummaryCardHeader({
  icon,
  title,
  onClickInfo,
  actionButton,
  expandCard,
}: SummaryCardHeaderProps) {
  const stackMobileStyles = useMemo(() => {
    return {
      marginBottom: expandCard ? 2 : undefined,
    };
  }, [expandCard]);
  const { isSmUp } = useBreakpoints();
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent={isSmUp ? 'flex-start' : 'space-between'}
      spacing={1}
      sx={isSmUp ? stackDesktopStyles : stackMobileStyles}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent={isSmUp ? 'space-between' : 'flex-start'}
        spacing={1}
        width="100%"
      >
        <Stack alignItems="center" direction="row" spacing={1}>
          {icon}
          <Typography variant="h5">{title}</Typography>
        </Stack>
        <IconButton aria-label="info" onClick={onClickInfo}>
          <InfoOutlinedIcon color={ColorNeutral600} />
        </IconButton>
      </Stack>
      {actionButton}
    </Stack>
  );
}
