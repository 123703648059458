import { InputAdornment, TextFieldProps } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { Kind } from '../../types/kind';
import { WithRequired } from '../../utils/withRequired';
import { IconButton } from '../IconButton';
import { DollarSignIcon } from '../Icons/DollarSignIcon';
import { ExpandMoreIcon } from '../Icons/ExpandMoreIcon';
import { VisibilityIcon } from '../Icons/VisibilityIcon';
import { VisibilityOffIcon } from '../Icons/VisibilityOffIcon';
import { CurrencyField } from './CurrencyField';
import { StyledField, StyledIconWrapper, StyledMenuItem } from './Field.styles';

export type FieldTypes = 'text' | 'currency' | 'select' | 'password';

export interface FieldProps
  extends Pick<
    WithRequired<TextFieldProps, 'onChange'>,
    | 'label'
    | 'disabled'
    | 'onChange'
    | 'value'
    | 'name'
    | 'onBlur'
    | 'sx'
    | 'inputProps'
  > {
  readonly type: FieldTypes;
  readonly kind?: Kind;
  readonly value: string;
  readonly iconLeft?: ReactNode;
  readonly menuItems?: { key: string; value: string; component?: ReactNode }[];
  readonly errorMsg?: string;
}

export function Field({
  type,
  kind = 'light',
  menuItems,
  iconLeft,
  errorMsg,
  ...rest
}: FieldProps) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  switch (type) {
    case 'password':
      return (
        <StyledField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={Boolean(errorMsg)}
          fullWidth
          helperText={errorMsg}
          id="password"
          kind={kind}
          label="Password"
          margin="normal"
          name="password"
          type={showPassword ? 'text' : 'password'}
          {...rest}
        />
      );
    case 'text':
      return (
        <StyledField
          InputProps={{
            startAdornment: <StyledIconWrapper>{iconLeft}</StyledIconWrapper>,
          }}
          error={Boolean(errorMsg)}
          fullWidth
          helperText={errorMsg}
          kind={kind}
          {...rest}
        />
      );
    case 'currency':
      return (
        <CurrencyField
          InputProps={{
            startAdornment: (
              <StyledIconWrapper>{iconLeft}</StyledIconWrapper>
            ) || <DollarSignIcon />,
          }}
          fullWidth
          kind={kind}
          {...rest}
        />
      );
    case 'select':
      return (
        <StyledField
          InputProps={{
            startAdornment: <StyledIconWrapper>{iconLeft}</StyledIconWrapper>,
          }}
          SelectProps={{ IconComponent: ExpandMoreIcon }}
          error={Boolean(errorMsg)}
          fullWidth
          helperText={errorMsg}
          kind={kind}
          select
          {...rest}
        >
          {menuItems?.map(({ key, value, component }) => (
            <StyledMenuItem key={`menuitem-${value}${key}`} value={key}>
              {component || value}
            </StyledMenuItem>
          ))}
        </StyledField>
      );
    default:
      throw new Error('Invalid prop value for `type`.');
  }
}
