import { useFiltersInitialDates } from '@/hooks/customHooks/useFiltersInitialDates';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useUserOrgCase } from '@context/UserOrgCaseContext';
import { FiltersForm } from '@shared/types/forms';
import { getTodayDate } from '@utils/dates';
import { decodeSearchParams, mapFilterToSearchQuery } from './utils';

export interface CaseDashboardProps {
  filters: FiltersForm;
  unsavedChanges: boolean;
  setUnsavedChanges: (value: boolean) => void;
  setFilters: (values: FiltersForm) => void;
  totalClaims?: number;
  setTotalClaimsCount?: (count: number) => void;
  handleClearFilterDates: () => void;
}

export const CaseDashboardContext = createContext<CaseDashboardProps>({
  filters: {},
  setFilters: () => undefined,
  totalClaims: undefined,
  unsavedChanges: false,
  setTotalClaimsCount: () => undefined,
  handleClearFilterDates: () => undefined,
  setUnsavedChanges: () => undefined,
});

export function CaseDashboardProvider({ children }: PropsWithChildren) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<FiltersForm>(
    decodeSearchParams(searchParams) as FiltersForm,
  );
  const { defaultStartDate, defaultEndDate } = useFiltersInitialDates();
  const [totalClaims, setTotalClaims] = useState<number>(0);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const { selectedCase, isLoading: isUserOrgCaseDataLoading } =
    useUserOrgCase();

  const today = useMemo(() => getTodayDate(), []);

  useEffect(() => {
    const activeFilters = {
      ...filters,
    };

    if (
      filters.startDate === defaultStartDate &&
      filters.endDate === defaultEndDate
    ) {
      activeFilters.startDate = undefined;
      activeFilters.endDate = undefined;
    }

    setSearchParams(mapFilterToSearchQuery(activeFilters));
  }, [
    selectedCase?.claimSubmissionStartDate,
    filters,
    searchParams,
    setSearchParams,
    today,
  ]);

  useEffect(() => {
    searchParams.forEach((value, key) => {
      const isRangeFilter =
        key === 'unitRange' || key === 'payoutRange' || key === 'scoreRange';

      setFilters((prevFilters) => ({
        ...prevFilters,
        [key]: isRangeFilter ? JSON.parse(value) : value,
      }));
    });
  }, [searchParams]);

  const handleClearFilterDates = useCallback(() => {
    setFilters({
      ...filters,
      startDate: undefined,
      endDate: undefined,
    });
    setSearchParams((prev) => {
      prev.delete('startDate');
      prev.delete('endDate');
      return prev;
    });
  }, [selectedCase]);

  const setTotalClaimsCount = useCallback((count: number) => {
    setTotalClaims(count);
  }, []);

  const caseDashboardValues = useMemo(
    () => ({
      filters,
      setFilters,
      totalClaims,
      setTotalClaimsCount,
      handleClearFilterDates,
      setUnsavedChanges,
      unsavedChanges,
    }),
    [
      setUnsavedChanges,
      filters,
      totalClaims,
      setTotalClaimsCount,
      handleClearFilterDates,
      unsavedChanges,
    ],
  );

  if (isUserOrgCaseDataLoading) {
    return null;
  }

  return (
    <CaseDashboardContext.Provider value={caseDashboardValues}>
      {children}
    </CaseDashboardContext.Provider>
  );
}

export const useCaseDashboardContext = () => {
  const context = React.useContext(CaseDashboardContext);

  if (!context) {
    throw new Error(
      'useCaseDashboardContext must be used within a CaseDashboardProvider',
    );
  }

  return context;
};
