import { styled } from '@mui/material';
import React from 'react';
import { Typography } from '../Typography';

export const StyledTypography = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(0)};
`;

interface SummaryCardTitleProps {
  readonly title: string;
}

export function SummaryCardTitle({ title }: SummaryCardTitleProps) {
  <StyledTypography variant="subtitle">{title}</StyledTypography>;
}
